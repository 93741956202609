import React, { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { Constant } from "../common/Constants";
import { ContextApiContext } from "../context/ContextApi";
import { SendRequestContetType } from "../common/Common";
import Nav_bar_area from "./NavBar";
import Map from "./Components/Map";
import Pagination from "./Components/Pagination";
import Footer from "./Components/Footer";
import "./../styles/Services_payment.css";
import "./../styles/categorydetaillisting.css";

export default function CategoryDetailListing() {
  const [bookmark, setBookmark] = useState([]); // Store bookmarked results
  const { updateContextState } = useContext(ContextApiContext); // To update context state for error or info messages
  const navigate = useNavigate(); // To handle navigation

  // Fetch bookmarked results when the component mounts
  useEffect(() => {
    console.log("init search");
    getBookmarkedResults(); // Fetch bookmarks on mount
  }, []);

  // Function to fetch bookmarked results from the API
  const getBookmarkedResults = async () => {
    try {
      const getBookmarkedResultsUrl = `${Constant.get_bookmarks}`;
      const res = await SendRequestContetType(
        "GET",
        getBookmarkedResultsUrl,
        null, // No payload needed for GET request
        true
      );

      console.log("Bookmarked Results Response:", res);
      if (res.status && res.response.length > 0) {
        setBookmark(res.response); // Set bookmarks if data is present
      } else {
        setBookmark([]); // If no bookmarks, clear the list
        updateContextState("No bookmarks found", "info_msg"); // Show message
      }
    } catch (error) {
      console.error("Error fetching bookmarked results:", error);
      updateContextState(
        "Failed to fetch bookmarked results. Please try again later.",
        "error_msg"
      ); // Error handling
    }
  };

  // Function to handle "Show More" button click and navigate to the community page
  const handleShowMoreClick = (result) => {
    navigate("/community_people", { state: { result } });
    window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scroll to the top
  };

  return (
    <div>
      <Nav_bar_area className="colooo" /> {/* Navigation Bar */}
      <div className="dggg">
        <Row>
          <Col>
            <h1>Bookmarked Results</h1> {/* Heading */}
          </Col>
        </Row>
        <Row>
          {bookmark.length === 0 ? (
            <Col xs={12}>
              <h4>No Bookmarked Results Found</h4>{" "}
              {/* Message if no bookmarks */}
            </Col>
          ) : (
            bookmark.map((result) => {
              console.log(result); // Log the result for debugging
              return (
                <Col xs={12} md={6} key={result.id}>
                  <div className="box">
                    <Row>
                      <Col xs={12} md={9}>
                        <div
                          onClick={() => handleShowMoreClick(result)}
                          className="inner-box1"
                        >
                          <h2>{result.name}</h2>
                          {/* Uncomment when category name is available */}
                          {/* <h6>{result.category_name}</h6> */}
                          <h6>
                            {result?.distance?.toFixed(2) ?? ""}{" "}
                            {result?.distance ? "KM" : ""}
                          </h6>
                          {result.address ? (
                            <h6>Address: {result.address}</h6>
                          ) : (
                            <h6>Address: No address available</h6>
                          )}
                          {/* Email */}
                          {result.email ? (
                            <h6>Email: {result.email}</h6>
                          ) : (
                            <h6>Email: No email available</h6>
                          )}

                          {/* Phone Numbers */}
                          {result.phone_numbers_json_format &&
                          result.phone_numbers_json_format.length > 0 ? (
                            <div>
                              {result.phone_numbers_json_format.map(
                                (phoneInfo, index) => (
                                  <div key={index}>
                                    <h6>
                                      <span className="phn_num">
                                        {phoneInfo.name}:
                                        <a href={`tel:${phoneInfo.phone}`}>
                                          {phoneInfo.phone}
                                        </a>
                                      </span>
                                    </h6>
                                  </div>
                                )
                              )}
                            </div>
                          ) : (
                            <h6>No phone numbers available</h6>
                          )}

                          <div className="show_area">
                            <Button variant="" className="bt show_btn">
                              Show More
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <div className="inner-box2">
                          <img
                            className="logo_ft"
                            src="/images/slicing/Help101-Web.png"
                            alt="Content"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })
          )}
        </Row>

        {/* Pagination Component */}
        <Pagination
          // Add pagination logic if needed
        />

        {/* Map Component */}
        <Row>
          <Map />
        </Row>
      </div>
      {/* Footer Component */}
      <Footer />
    </div>
  );
}
